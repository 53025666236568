import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  private _isDestroyed$: Subject<boolean> = new Subject<boolean>();

  /**
   * If this component is visible or hidden
   */
  public isVisible = false;

  ngOnDestroy(): void {
    this._isDestroyed$.next(true);
    this._isDestroyed$.complete();
  }

  /**
   * Keep emitting values from the source Observable until this component is destroyed.
   * @protected
   */
  protected takeUntilIsDestroyed(): MonoTypeOperatorFunction<any> {
    return takeUntil(this._isDestroyed$);
  }
}
