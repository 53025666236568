<div
  class="new__banner"
  [ngClass]="{
    'banner-margin-top': addMarginTop,
  }"
  *ngIf="viewState.imageUrl && isDynamicStrapEnabled"
>
  <img
    (click)="viewState.formLink && presenter.emitViewEvent({ type: 'ClickBanner' })"
    class="banner__image"
    src="{{ viewState.imageUrl }}"
    alt="{{ viewState.formLink }}"
  />
</div>
