export const removeImageSizeFromURL = (url: string): string => {
  const imageSizeRemovalRegex = /(.+\.com)(\/\d+x\d+\/)(.+)/;
  if (imageSizeRemovalRegex.test(url)) {
    const regexMatch = url.match(imageSizeRemovalRegex);
    const [, imageUrl, , imageName] = regexMatch!;
    const unSizedImageUrl = `${imageUrl}/${imageName}`;
    return unSizedImageUrl;
  }
  return url;
};
