<loader [loading]="isLoading" class="loader"></loader>
<div class="tamara-popup-container">
  <div class="d-flex logo-container">
    <img [src]="dialogData?.isLTR ? 'assets/img/tamara.svg' : 'assets/img/tamara-ar.svg'" />
    <button
      (click)="presenter.emitViewEvent({ type: 'ClickCloseDialog' })"
      class="dialog-close-button"
    >
      <span>&times;</span>
    </button>
  </div>
  <p class="mt-3 caption1--regular orange-highlight">
    {{ 'PRODUCTS_PAGE.TAMARA_POPUP.SUB_TITLE' | translate }} ⚡️
  </p>
  <p class="title heading2--extra-bold mt-2">
    {{ 'PRODUCTS_PAGE.TAMARA_POPUP.TITLE' | translate }}.
  </p>
  <p class="description mt-4 caption2--regular">
    {{ 'PRODUCTS_PAGE.TAMARA_POPUP.DETAIL' | translate }}
    <span
      ><a href="https://tamara.co/ar-SA" target="_blank">{{
        'PRODUCTS_PAGE.TAMARA_POPUP.LABEL' | translate
      }}</a></span
    >.
  </p>

  <div class="mt-4 checkbox-container">
    <mat-checkbox
      [(ngModel)]="viewState.hasTamaraIntegrationEnabled"
      [disabled]="viewState.loading"
    ></mat-checkbox>
    <p class="caption1--bold">{{ 'PRODUCTS_PAGE.TAMARA_POPUP.CHECKBOX_TEXT' | translate }}</p>
  </div>
  <button
    class="save-btn body2--regular mt-4"
    type="button"
    (click)="presenter.emitViewEvent({ type: 'ClickSavePreference' })"
  >
    {{ 'PRODUCTS_PAGE.TAMARA_POPUP.BUTTON_TEXT' | translate }}
  </button>
  <p class="mt-4 caption2--regular text-center">
    {{ 'PRODUCTS_PAGE.TAMARA_POPUP.SUB_TEXT' | translate }}
    <span
      ><a routerLink="/orders">{{
        'PRODUCTS_PAGE.TAMARA_POPUP.PAGE_LOCATION' | translate
      }}</a></span
    >
  </p>
</div>
