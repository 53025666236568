import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { OnboardingStoryGuideStateManager } from '../onboarding/state-manager/onboarding-story-guide.state-manager';
import { SelectCurrentActiveUserStepIndex } from '../onboarding/state-manager/selectors/selector-names';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import { TooltipDirective } from '../shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'app-tap-to-learn',
  templateUrl: './tap-to-learn.component.html',
  styleUrls: ['./tap-to-learn.component.scss'],
  standalone: true,
  imports: [TooltipDirective, NgClass, RouterLink, AsyncPipe],
})
export class TapToLearnComponent implements OnInit, OnDestroy {
  public assetsPath = '../../../../../assets/img/on-boarding/';

  public currentActiveStepSelector$: Observable<number>;

  public tooltipComponentRef = 'OnboardingNextStepTooltipComponent';

  public tapToLearnImg: string;

  private _languageChangeSubscription: Subscription;

  @ViewChild('learningCenterElement', { static: true, read: ViewContainerRef })
  public learningCenterElementRef: ViewContainerRef;

  constructor(
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _translateService: TranslateService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    this.currentActiveStepSelector$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectCurrentActiveUserStepIndex,
    );
  }

  ngOnInit(): void {
    this._setImageNameAccordingToLanguage(this._translateService.currentLang);
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this._setImageNameAccordingToLanguage(event.lang);
      },
    });
  }

  private _setImageNameAccordingToLanguage(language: string): void {
    this.tapToLearnImg =
      language === ENGLISH_LANGUAGE ? 'en-tap-to-learn.svg' : 'ar-tap-to-learn.svg';
  }

  onTapToLearnClicked(): void {
    this._logMixpanelEventUseCase.execute({
      eventName: 'learning_center_button_clicked',
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
