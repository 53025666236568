export const PRODUCT_REVIEW_OPTIONS = [
  {
    arabicText: 'لم أفهم المنتج، محتوى غير واضح',
    englishText: "I didn't understand the product, content is not clear",
  },
  { arabicText: 'مشكلة في التسعير', englishText: 'There is a pricing problem' },
  {
    arabicText: 'منتج جيد، طلب توفير كمية',
    englishText: 'Product is good, but needs more available stock',
  },
  {
    arabicText: 'طلب عرض مع منتج اخر (الرجاء توضيح كود المنتج الآخر في الملاحظات)',
    englishText:
      'Requesting to be bundled with another product (Please specify the product SKU(s) to bundle with in the notes)',
  },
  {
    arabicText: 'طلب عرض مع نفس المنتج',
    englishText: 'Requesting a bundle of multiple quantities of the same product',
  },
  { arabicText: 'أحتاج فيديو جديد للمنتج', englishText: 'I need a new video for the product' },
  { arabicText: 'مشكلة في جودة المنتج', englishText: 'A problem with the product quality' },
];
