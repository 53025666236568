<div class="d-flex align-items-center breadcrumbs-container">
  <a
    class="breadcrumb-category"
    *ngIf="!isNewPreOrderEnabled"
    [routerLink]="appURLs.PRODUCTS_V2_URL"
  >
    {{ 'CATEGORY_PAGE.PRODUCTS_BREADCRUMB' | translate }}
  </a>
  <div
    *ngFor="let category of navigationHeirarchyArray; index as index"
    class="d-flex align-items-center"
  >
    <i
      class="icon"
      [ngClass]="isEnglishLanguageSelected ? 'icon-right-chevron' : 'icon-left-chevron'"
      aria-hidden="true"
    ></i>
    <a
      class="breadcrumb-category"
      [ngClass]="{ 'last-category': index === navigationHeirarchyArray.length - 1 }"
      (click)="onCategoryClicked(category)"
    >
      {{
        isEnglishLanguageSelected ? category.value.name.englishName : category.value.name.arabicName
      }}
    </a>
  </div>
</div>
