import { inject, Injectable } from '@angular/core';
import { finalize } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ProfileService } from '../../../services/profile.service';
import { TamaraPreferenceSideEffect } from './tamara-preference.sideEffects';
import { TamaraPreferenceViewEvents } from './tamara-preference.viewEvents';
import { TamaraPreferenceViewState } from './tamara-preference.viewState';

@Injectable()
export class TamaraPreferencePresenter extends BasePresenter<
  TamaraPreferenceViewState,
  TamaraPreferenceViewEvents,
  TamaraPreferenceSideEffect
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(private _profileService: ProfileService) {
    super();
  }

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): TamaraPreferenceViewState {
    return {
      loading: true,
      hasTamaraIntegrationEnabled: false,
    };
  }

  protected onViewEvent(event: TamaraPreferenceViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._profileService
          .getUserProfile()
          .pipe(
            finalize(() => {
              this.viewState.loading = false;
            }),
          )
          .subscribe(({ data }) => {
            this.viewState.hasTamaraIntegrationEnabled = data.enableTamaraPayment !== false;
          });
        break;
      }

      case 'ClickSavePreference': {
        this._logMixpanelEventUseCase.execute({
          eventName: 'save_tamara_preference_button_clicked',
        });
        this.emitSideEffect({ type: 'SavePreference' });
        break;
      }

      case 'ClickCloseDialog': {
        this.emitSideEffect({ type: 'CloseDialog' });
        break;
      }
    }
  }
}
