/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Injectable } from '@angular/core';
import { UserService } from 'app/presentation/shared/services/user.service';
import { finalize } from 'rxjs/operators';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from '../../base/base.presenter';
import { SurveyFormBannerSideEffect } from './survey-form-banner.side-effect';
import { SurveyFormBannerViewEvent } from './survey-form-banner.view-event';
import { SurveyFormBannerViewState } from './survey-form-banner.view-state';

@Injectable({ providedIn: 'root' })
export class SurveyFormBannerPresenter extends BasePresenter<
  SurveyFormBannerViewState,
  SurveyFormBannerViewEvent,
  SurveyFormBannerSideEffect
> {
  private bannerContent = {
    title: 'SURVEY_BANNER.TITLE',
    description: 'SURVEY_BANNER.DESCRIPTION',
    buttonTitle: 'SURVEY_BANNER.CTA',
    formLink: 'https://2hlx9cbqbxc.typeform.com/novemberoffer?utm_source=xxxxx',
    iconName: 'saudi-flag',
  };

  private iraqBannerContent = {
    title: 'SURVEY_BANNER.IQ_TITLE',
    description: 'SURVEY_BANNER.IQ_DESCRIPTION',
    buttonTitle: 'SURVEY_BANNER.CTA',
    formLink: 'https://2hlx9cbqbxc.typeform.com/taagerIraq?typeform-source=taager.com',
    iconName: 'iraq-flag',
  };

  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private userService: UserService,
  ) {
    super();
  }

  protected defaultViewState(): SurveyFormBannerViewState {
    return {
      isLoading: false,
      imageUrl: '',
      formLink: '',
    };
  }

  onViewEvent(event: SurveyFormBannerViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.getStrap();
        break;
      }
      case 'ClickBanner': {
        this._logMixpanelEventUseCase.execute({ eventName: 'ux_interviews_form_join_now_clicked' });
        this.emitSideEffect({ type: 'NavigateToForm', link: this.viewState.formLink });
        break;
      }
    }
  }

  getStrap(): void {
    this.viewState.isLoading = true;
    this.userService
      .getStrap()
      .pipe(
        finalize(() => {
          this.viewState.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.viewState.imageUrl = res.data?.[0].img;
          this.viewState.formLink = res.data?.[0].link;
        },
      });
  }
}
