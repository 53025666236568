import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerQuestionnaireService {
  private _declineBanner: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) {}

  public getDeclineBannerValue() {
    return this._declineBanner;
  }

  public setDeclineBannerValue(value: boolean) {
    this._declineBanner.next(value);
  }
}
