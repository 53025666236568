import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { UpdateProfileUseCase } from 'src/app/core/usecases/user/update-profile-usecase';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { TAMARA_PREFERENCE_KEY } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { LoaderComponent } from '../loader/loader.component';
import { TamaraPreferencePresenter } from './presenter/tamara-preference.presenter';
import { TamaraPreferenceSideEffect } from './presenter/tamara-preference.sideEffects';
import { TamaraPreferenceViewEvents } from './presenter/tamara-preference.viewEvents';
import { TamaraPreferenceViewState } from './presenter/tamara-preference.viewState';

@Component({
  selector: 'app-tamara-integration-popup',
  templateUrl: './tamara-integration-popup.component.html',
  styleUrls: ['./tamara-integration-popup.component.scss'],
  standalone: true,
  providers: [TamaraPreferencePresenter],
  imports: [
    MatCheckboxModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    LoaderComponent,
    RouterModule,
  ],
})
export class TamaraIntegrationPopupComponent extends BaseComponent<
  TamaraPreferencePresenter,
  TamaraPreferenceViewState,
  TamaraPreferenceViewEvents,
  TamaraPreferenceSideEffect
> {
  public isLoading = false;

  public presenter: TamaraPreferencePresenter = inject(TamaraPreferencePresenter);

  constructor(
    public _dialogRef: MatDialogRef<TamaraIntegrationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { isLTR: boolean },
    private localStorageService: LocalStorageService,
    private _toastService: ToastrService,
    private _updateProfileUseCase: UpdateProfileUseCase,
    private _translateService: TranslateService,
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  onSideEffect(sideEffect: TamaraPreferenceSideEffect): void {
    switch (sideEffect.type) {
      case 'SavePreference': {
        this.savePreferences();
        break;
      }
      case 'CloseDialog': {
        this.closeDialog();
        break;
      }
    }
  }

  public closeDialog(): void {
    this.setPopupKey();
    this._dialogRef.close();
  }

  public savePreferences(): void {
    this.setPopupKey();
    this.isLoading = true;
    this._updateProfileUseCase
      .execute({
        enableTamaraPayment: this.viewState.hasTamaraIntegrationEnabled,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this._toastService.success(
            this._translateService.instant('ORDERS_PAGE.ORDER_PREFERENCES_DIALOG.SUCCESS_MESSAGE'),
          );
          this._dialogRef.close();
        },
        error: () => {
          this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE');
        },
      });
  }

  public setPopupKey(): void {
    this.localStorageService.setStorage(TAMARA_PREFERENCE_KEY, true);
  }
}
