import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlashSalesService {
  public checkAllSaleStartAndExpiry(data: any, singleProduct?: any) {
    const products: any = [];
    if (!data.length) {
      products.push(data);
    }
    const productsArray = singleProduct ? products : data;
    productsArray.forEach((product: any) => {
      const saleStartTime = product.sale?.saleStartsAt;
      const saleExpireTime = product.sale?.saleExpiresAt;
      if (saleStartTime) {
        const formatSaleStartTime = new Date(saleStartTime.toString().slice(0, -1));
        if (
          (product.sale?.productProfit || product.sale?.productPrice) &&
          formatSaleStartTime.getTime() >= new Date().getTime()
        ) {
          product.customPrice = product.productPrice;
          product.sale = null;
        }
      }
      if (saleExpireTime) {
        const formatSaleExpireTime = new Date(saleExpireTime.toString().slice(0, -1));
        if (
          (product.sale?.productProfit || product.sale?.productPrice) &&
          formatSaleExpireTime.getTime() <= new Date().getTime()
        ) {
          product.customPrice = product.productPrice;
          product.sale = null;
        }
      }
    });
    if (singleProduct) {
      return productsArray[0];
    }
    return productsArray;
  }
}
