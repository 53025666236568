import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { DeclineQuestionnaireUseCase } from 'src/app/core/usecases/post-decline-questionnaire';
import { BannerQuestionnaireService } from 'src/app/presentation/shared/services/banner-questionnaire.service';
import { KSA_QUESTIONNAIRE_NAME } from '../../constants/questionnaire-name';

@Component({
  selector: 'app-questionnaire-banner',
  templateUrl: './questionnaire-banner.component.html',
  styleUrls: ['./questionnaire-banner.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class QuestionnaireBannerComponent {
  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private declineQuestionnaireUseCase: DeclineQuestionnaireUseCase,
    private toastService: ToastrService,
    private bannerQuestionnaireService: BannerQuestionnaireService,
    private _translateService: TranslateService,
  ) {}

  public declineSurvey(): void {
    this.declineQuestionnaireUseCase.execute(KSA_QUESTIONNAIRE_NAME).subscribe({
      next: (res) => {
        this.toastService.success(this._translateService.instant('SURVEY.QUESTIONNAIRE_REJECTED'));
      },
      error: (err) => {
        this.toastService.error(err?.error?.msg);
      },
      complete: () => {
        this.bannerQuestionnaireService.setDeclineBannerValue(false);
        this._logMixpanelEventUseCase.execute({ eventName: 'ksa_banner_decline_click' });
      },
    });
  }

  public trackQuestionnaire() {
    this._logMixpanelEventUseCase.execute({ eventName: 'ksa_banner_questionnaire_click' });
  }
}
