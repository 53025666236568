<div
  class="learning-center"
  [ngClass]="{ 'is-active-onboarding-step': (currentActiveStepSelector$ | async) === 4 }"
  #learningCenterElement
  appTooltip
  [appTooltipIsShown]="
    (currentActiveStepSelector$ | async) === 4 && learningCenterElementRef !== undefined
  "
  [appTooltipCaretConfig]="{
    hasCaret: true,
    height: 37,
    width: 37,
    positionOnOrigin: 'right',
    placementY: '40px',
    color: 'white',
    offsetFromHostElement: 20
  }"
  [appTooltipInjectionPayload]="{
    componentToInject: tooltipComponentRef,
    data: {
      currentStep: 4,
      navigateTo: {
        link: 'learning-center'
      },
      uiContent: {
        title: {
          classes: 'heading2--bold',
          styling: {
            'column-gap': '8px'
          },
          sections: [
            {
              text: 'ONBOARDING.STEPS.1.HEADER.a',
              styling: {
                color: '#0F1933'
              }
            },
            {
              text: 'ONBOARDING.STEPS.1.HEADER.b',
              styling: {
                color: '#46B8B0'
              }
            }
          ]
        },
        body: {
          paragraphs: [
            {
              classes: 'body2--regular',
              styling: {
                'text-align': 'initial'
              },
              sections: [
                {
                  text: 'ONBOARDING.STEPS.1.BODY.a',
                  styling: {
                    'font-weight': '400',
                    color: '#0F1933'
                  }
                },
                {
                  text: 'ONBOARDING.STEPS.1.BODY.b',
                  styling: {
                    'font-weight': '700',
                    color: '#0F1933'
                  }
                },
                {
                  text: 'ONBOARDING.STEPS.1.BODY.c',
                  styling: {
                    'font-weight': '400',
                    color: '#0F1933'
                  }
                }
              ]
            }
          ]
        },
        footer: {
          classes: 'body2--medium',
          styling: {
            'justify-content': 'flex-end'
          },
          buttons: [
            {
              action: 'next',
              styling: {
                padding: '16px 48px',
                'column-gap': '8px'
              },
              sections: [
                {
                  type: 'text',
                  content: 'ONBOARDING.STEPS.1.FOOTER.a'
                },
                {
                  type: 'image',
                  content: assetsPath + 'next-step-left-arrow.svg'
                }
              ]
            }
          ]
        }
      }
    }
  }"
  [appTooltipConnectedElement]="learningCenterElementRef"
  [appTooltipAttachedToElement]="learningCenterElement"
  [appTooltipStyling]="{
    'background-color': 'white',
    'border-radius': '10px'
  }"
  [appTooltipOverlayPosition]="{
    positionOnOrigin: 'left',
    placementY: '0px'
  }"
  [appTooltipDimensions]="{
    width: '502px'
  }"
  [appTooltipHasBackdrop]="true"
  [appTooltipParentCustomStyling]="{
    padding: 0
  }"
>
  <a [routerLink]="[appURLs.LEARNING_CENTER_URL]" (click)="onTapToLearnClicked()">
    <img src="{{ assetsPath + tapToLearnImg }}" />
  </a>
</div>
